import { Popover, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import ColorPicker from '@components/plots/ColorPicker';
import cn from 'classnames';
import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import Button from '@components/Button';
import { TrashIcon } from '@heroicons/react/outline';
import { ThemeColor } from '@models/PlotConfigs';
import { isBlank } from '@util/StringUtil';
import { autoUpdate, autoPlacement, useFloating } from '@floating-ui/react-dom';
import { createPortal } from 'react-dom';
import ConditionalWrapper from '../../ConditionalWrapper';

type Props = PropsWithChildren<{
    allowTransparent?: boolean;
    bottomOffsetClassname?: string;
    cancelText?: ReactNode;
    color?: string;
    customColors?: string[];
    customColorsLabel?: string;
    defaultColor?: string;
    hideThemes?: boolean;
    labelName?: string;
    leftOffsetClassName?: string;
    offsetLeft?: boolean;
    onChange?: (newColor: string) => void;
    onRemove?: () => void;
    removeText?: ReactNode;
    saveText?: ReactNode;
    secondaryCustomColors?: string[];
    secondaryCustomColorsLabel?: string;
    showRemove?: boolean;
    themeColor?: ThemeColor;
    title?: ReactNode;
    useFloating?: boolean;
}>;
const GroupColorPickerPopover = ({
    allowTransparent,
    bottomOffsetClassname,
    cancelText = 'Cancel',
    children,
    color: initialColor,
    customColors,
    customColorsLabel,
    defaultColor,
    hideThemes = false,
    labelName = 'Group name',
    leftOffsetClassName,
    offsetLeft = true,
    onChange,
    onRemove,
    removeText = 'Clear',
    saveText = 'Apply',
    secondaryCustomColors,
    secondaryCustomColorsLabel,
    showRemove = false,
    themeColor,
    title,
    useFloating: useFloatingProp = false,
}: Props) => {
    const [color, setColor] = useState<string>(initialColor?.toUpperCase() ?? '#ffffff');
    const { floatingStyles, refs, update } = useFloating({
        placement: 'right-end',
        middleware: [autoPlacement()],
    });
    const portalContainer = document.body;

    useEffect(() => {
        if (useFloatingProp && refs.reference.current && refs.floating.current) {
            return autoUpdate(refs.reference.current, refs.floating.current, update);
        }
    }, [useFloatingProp, refs.reference, refs.floating, update]);

    useEffect(() => {
        if (initialColor) {
            setColor(initialColor?.toUpperCase());
        }
    }, [initialColor]);

    return (
        <Popover className="relative">
            {({ open, close }) => (
                <>
                    <Popover.Button
                        ref={refs.setReference}
                        className="text-medium relative z-0 flex items-center"
                        as="span"
                    >
                        {children ?? (
                            <>
                                <span>Choose Color</span>{' '}
                                <ChevronRightIcon
                                    className={cn('transition', { 'rotate-90 transform': open })}
                                    width={18}
                                    aria-hidden="true"
                                />
                            </>
                        )}
                    </Popover.Button>

                    <ConditionalWrapper
                        condition={useFloatingProp}
                        wrapper={(children) => <>{createPortal(children, portalContainer)}</>}
                    >
                        <Popover.Panel
                            ref={refs.setFloating}
                            style={useFloatingProp ? floatingStyles : undefined}
                            className={cn(
                                'z-10 absolute rounded-xl border border-gray-200 bg-white p-4 shadow-lg h-fit',
                                {
                                    'left-6': offsetLeft && isBlank(leftOffsetClassName),
                                    'bottom-6': isBlank(bottomOffsetClassname),
                                },
                                leftOffsetClassName,
                                bottomOffsetClassname,
                            )}
                        >
                            <Transition
                                className="relative z-50"
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                            >
                                <div className="space-y-4">
                                    <div className="space-y-2">
                                        {title && (
                                            <div>
                                                <p className="font-semibold tracking-tight text-gray-500">
                                                    {labelName}
                                                </p>
                                                <span className="text-lg font-semibold text-dark">{title}</span>
                                            </div>
                                        )}
                                        <ColorPicker
                                            allowTransparent={allowTransparent}
                                            color={color}
                                            customColors={customColors}
                                            customColorsLabel={customColorsLabel}
                                            defaultColor={defaultColor}
                                            hideThemes={hideThemes}
                                            secondaryCustomColors={secondaryCustomColors}
                                            secondaryCustomColorsLabel={secondaryCustomColorsLabel}
                                            setColor={setColor}
                                            themeColor={themeColor}
                                        />
                                    </div>
                                    <div
                                        className={cn('flex space-x-2', {
                                            'justify-between': showRemove,
                                            'justify-end': !showRemove,
                                        })}
                                    >
                                        {showRemove && (
                                            <Button
                                                color="primary"
                                                variant="text"
                                                size="small"
                                                onClick={() => {
                                                    onRemove?.();
                                                    close();
                                                }}
                                                startIcon={<TrashIcon width={14} className="text-error" />}
                                            >
                                                <span className="text-error">{removeText}</span>
                                            </Button>
                                        )}
                                        <div className="flex justify-end space-x-4 ">
                                            <Button color="primary" variant="text" size="small" onClick={() => close()}>
                                                {cancelText}
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => {
                                                    onChange?.(color);
                                                    close();
                                                }}
                                            >
                                                {saveText}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Transition>
                        </Popover.Panel>
                    </ConditionalWrapper>
                </>
            )}
        </Popover>
    );
};

export default GroupColorPickerPopover;

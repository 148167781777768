import useSWR from 'swr';
import Endpoints from '@services/Endpoints';
import useApi from '@hooks/useApi';
import ApiToken from '@models/ApiToken';
import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import { EyeIcon, EyeOffIcon, PlusIcon } from '@heroicons/react/solid';
import { IconButton } from '@mui/material';
import { TimeoutValue } from '@util/ObjectUtil';
import cn from 'classnames';
import { isDefined } from '@util/TypeGuards';
import { ApiError } from '@services/ApiError';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import ConfirmModal from '@components/ConfirmModal';
import Logger from '@util/Logger';
import { CopyIcon } from '@components/icons/custom/CopyIcon';
import AlertWrapper from './AlertWrapper';

const SUCCESS_TIMEOUT = 4000;
const logger = Logger.make('ApiTokenForm');

type Props = {
    disableDelete?: boolean; // New optional prop to control delete button visibility
};

const ApiTokenForm = ({ disableDelete = false }: Props) => {
    const api = useApi();
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [saving, setSaving] = useState(false);
    const [showToken, setShowToken] = useState(false);
    const [copySuccessTimeout, setCopySuccessTimeout] = useState<TimeoutValue | null>(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const { data: tokens, mutate: mutateTokens } = useSWR<ApiToken[]>(Endpoints.user.tokens());

    const token = tokens?.[0] ?? null;

    useEffect(() => {
        return () => {
            if (copySuccessTimeout) {
                clearTimeout(copySuccessTimeout);
                setCopySuccessTimeout(null);
            }
        };
    }, []);

    const handleCreate = async () => {
        setSaving(true);
        try {
            const createdToken = await api.post<ApiToken>(Endpoints.user.tokens());
            setShowToken(true);
            await mutateTokens((current) => (current ? [createdToken, ...current] : [createdToken]), false);
        } catch (error) {
            logger.error('API Token create error', {}, error);
        } finally {
            setSaving(false);
        }
    };

    const deleteToken = async () => {
        if (!token?.uuid) {
            setErrorMessage('Unable to delete. No token found.');
            return;
        }

        if (!showDeleteConfirm) {
            setShowDeleteConfirm(true);
            return;
        }

        try {
            setErrorMessage(null);
            await mutateTokens([], false);
            await api.doDelete(Endpoints.user.token({ tokenId: token.uuid }));
            setShowDeleteConfirm(false);
            await mutateTokens();
        } catch (error) {
            logger.error(error);
            setErrorMessage(ApiError.getMessage(error as Error));
        }
    };

    const handleCopyClicked = async () => {
        if (navigator.clipboard && token?.key) {
            await navigator.clipboard.writeText(token.key);
            const timeout = setTimeout(() => {
                setCopySuccessTimeout(null);
            }, SUCCESS_TIMEOUT);
            setCopySuccessTimeout(timeout);
        }
    };

    return (
        <div>
            {errorMessage && <AlertWrapper message={errorMessage} />}
            {token && (
                <div>
                    <div className={'form-field space-y-1'}>
                        <div className="relative">
                            <input
                                type={showToken ? 'text' : 'password'}
                                value={token.key}
                                className={cn('field-input', {
                                    '!pr-28': isDefined(copySuccessTimeout),
                                    '!pr-16': !isDefined(copySuccessTimeout),
                                })}
                                readOnly
                            />
                            <span className="absolute right-0 top-0 z-10 flex h-full items-center space-x-1 pr-1">
                                {copySuccessTimeout ? (
                                    <span className="px-2 font-semibold text-primary">Copied!</span>
                                ) : (
                                    <IconButton
                                        sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                        size="small"
                                        onClick={handleCopyClicked}
                                    >
                                        <CopyIcon height={18} width={18} />
                                    </IconButton>
                                )}
                                <IconButton
                                    sx={{ '& .MuiIconButton-label': { lineHeight: 1 } }}
                                    size="small"
                                    onClick={() => setShowToken((current) => !current)}
                                >
                                    {showToken ? <EyeOffIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
                                </IconButton>
                            </span>
                        </div>
                        {!disableDelete && (
                            <div>
                                <Button onClick={deleteToken} variant="text" className="text-error ">
                                    <DeleteOutlineRoundedIcon fontSize="small" color="error" />
                                    <span className="text-error">Delete token</span>
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!token && (
                <div>
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={handleCreate}
                        loading={saving}
                        disabled={saving}
                    >
                        <PlusIcon className="mr-1 h-3.5 w-3.5" /> API token
                    </Button>
                </div>
            )}

            <ConfirmModal
                open={showDeleteConfirm}
                onConfirm={deleteToken}
                onCancel={() => setShowDeleteConfirm(false)}
                title="Delete API token"
                message="Are you sure you want to delete this API token? Once deleted, any scripts or apps using this API token will no longer have access to fetch data."
                confirmText="Yes, delete"
                cancelText="Cancel"
            />
        </div>
    );
};

export default ApiTokenForm;
